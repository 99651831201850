@import 'utils/mixins.scss';
@import 'utils/variables.scss';

.home {
  @include page-center-layout;
  @include drag-protect;
  &--banner {
    img {
      display: block;
      width: 80%;
      margin: 0 auto 1.2rem;
    }
  }
  &--button {
    padding: 0 5rem;
    a {
      display: block;
      color: $color-gray-1;
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      margin-top: 1.8rem;
      text-align: center;
      &:hover {
        color: $color-gray-3;
      }
    }
  }
  &--desc {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 4.3rem 0;
    background: $color-wrapper-background;
    color: $color-gray-3;
    text-align: center;
  }
}
