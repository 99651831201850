@import 'utils/variables.scss';

.input {
  background-color: $color-white;
  &:hover {
    border-color: $color-orange-1;
  }
  &:focus {
    border-color: $color-orange-1;
  }
}
