@import 'utils/variables.scss';

// ****************
// Media Query
// ****************

$breakpoints: (
  // max
  'max-xs':
    (
      max-width: $mobile-portrait,
    ),
  'max-s': (
    max-width: $mobile-landscape,
  ),
  'max-m': (
    max-width: $tablet-portrait,
  ),
  'max-l': (
    max-width: $tablet-landscape,
  ),
  'max-lg': (
    max-width: $desktop,
  ),
  // min
  'min-xs':
    (
      min-width: $mobile-portrait,
    ),
  'min-s': (
    min-width: $mobile-landscape,
  ),
  'min-m': (
    min-width: $tablet-portrait,
  ),
  'min-l': (
    min-width: $tablet-landscape,
  ),
  'min-lg': (
    min-width: $desktop,
  )
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// ****************
// LayOut
// ****************
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center {
  @include flex-column;
  align-items: center;
}

@mixin page-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 8rem);
}

@mixin page-center-layout {
  @include page-layout;
  justify-content: center;
  padding-bottom: 7.2rem;
}

// ****************
// SpaceType
// ****************

@mixin space-clear {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

// ****************
// FormType
// ****************

@mixin button-style {
  width: 100%;
  min-width: 6.5rem;
  height: 4rem;
  font-family: $font-stack-ko;
  font-weight: $font-weight-normal;
  font-size: $font-size-3;
}

// ****************
// Function
// ****************

@mixin drag-protect {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin nav-sliding-time {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}
