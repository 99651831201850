@import 'utils/variables.scss';

.wordbox {
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 1.8rem;
  &-title {
    position: relative;
    padding: 1.75rem 0;
    background-color: $color-white;
    border: 1px solid $color-wordbox-background;
    font-weight: $font-weight-bold;
    font-size: $font-size-1;
    color: $color-gray-0;
    text-align: center;
    box-shadow: 0 0.2rem 1.4rem rgba(0, 0, 0, 0.1);
    span {
      display: inline-block;
      min-width: 6.8rem;
      background-color: $color-white;
    }
    &.orange {
      color: $color-orange-1;
    }
    &.blue {
      color: $color-success;
    }
    &.red {
      color: $color-fail;
    }
  }
  &-body {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.1rem 2rem;
    background-color: $color-wordbox-background;
    color: $color-gray-2;
    font-size: $font-size-4;
    span {
      display: block;
      line-height: 1.4;
    }
  }
}
