@import 'utils/variables.scss';
@import 'utils/mixins.scss';
.sidebar {
  @include flex-column-center;
  @include nav-sliding-time;
  @include drag-protect;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 20rem;
  height: 100vh;
  padding: 6.2rem 0 4rem;
  background-color: $color-orange-1;
  z-index: 11;
  &.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  &.deactive {
    -webkit-transform: translateX(20rem);
    -ms-transform: translateX(20rem);
    transform: translateX(20rem);
  }
  &__top {
    @include flex-column-center;
    width: 100%;
    padding-top: 7.6rem;
    .link {
      &__container {
        @include flex-column-center;
        margin-top: 6rem;
        width: 100%;
      }
      &__item {
        display: inline-block;
        width: 100%;
        height: 4rem;
        line-height: 4rem;
        font-weight: $font-weight-bold;
        font-size: $font-size-3;
        text-align: center;
        color: $color-white;
        span {
          display: inline-block;
        }
        &:hover {
          background-color: $color-orange-2;
        }
      }
    }
  }
  &__bottom {
    &--title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.2rem;
      p {
        margin-right: 0.4rem;
        color: $color-white;
        font-weight: $font-weight-bold;
        font-size: $font-size-3;
      }
    }
    &--items {
      @include flex-column-center;
      a {
        opacity: 0.5;
        margin-bottom: 0.2rem;
        font-size: $font-size-5;
        color: $color-white;
        line-height: 1.4rem;
        cursor: pointer;
        &:hover {
          color: $color-white;
          opacity: 1;
        }
      }
    }
  }
}
