@import 'utils/variables.scss';

.slider {
  width: 300%;
  transition: 0.6s ease-in-out;
  &::after {
    display: block;
    content: '';
    clear: both;
  }
  &__box {
    float: left;
    &--text {
      width: 100%;
      padding-bottom: 2.8rem;
    }
  }
}
