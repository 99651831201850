@import 'utils/mixins.scss';

.result {
  @include page-layout;
  &--title {
    @include drag-protect;
    margin-bottom: 3rem;
    &:nth-of-type(1) {
      padding-top: 3.2rem;
    }
  }
  &--word {
    padding-bottom: 3rem;
  }
}
