.img {
  &--container {
    position: relative;
    height: 18.4rem;
    margin-top: 3rem;
  }
  &--box {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    &.active {
      opacity: 1;
    }

    img {
      display: inline-block;
      height: 100%;
    }
  }
}
