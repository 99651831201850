@import 'utils/variables.scss';

.round-timer {
  @keyframes showTimer {
    0% {
      opacity: 0%;
      transform: scale(0.5);
      margin-top: 6.3rem;
    }
    5% {
      opacity: 100%;
      margin-top: 1.2rem;
    }
    10% {
      transform: scale(1);
    }
    30% {
      margin-top: 0px;
    }
    50% {
      margin-top: 2rem;
    }
    70% {
      margin-top: 3rem;
    }
    90% {
      transform: scale(1);
      margin-top: 2rem;
    }
    100% {
      margin-top: 4rem;
      transform: scale(0);
    }
  }
  position: relative;
  width: 19rem;
  height: 19rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 4s showTimer ease-in;
  animation-iteration-count: 1;
  transform: scale(0);

  &--number {
    font-family: $font-stack-en;
    font-size: 3rem;
    font-weight: $font-weight-bold;
    letter-spacing: -0.1rem;
    color: $color-orange-1;
  }

  &--inner {
    position: absolute;
    width: 10rem;
    height: 10rem;
    @keyframes defaultRotate {
      0% {
        transform: rotate(-90deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    animation: 4s defaultRotate ease-in;

    svg {
      width: 100%;
      height: 100%;
      circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: $color-white;
        stroke-width: 10;
        stroke-linecap: round;

        &:nth-child(2) {
          @keyframes drawStroke {
            0% {
              stroke-dasharray: 432;
              stroke-dashoffset: 432;
            }
            20% {
              stroke-dashoffset: 300;
              stroke-dashoffset: 400;
            }
            100% {
              stroke-dashoffset: -432;
              stroke-dashoffset: -432;
            }
          }
          stroke: $color-orange-1;
          animation: 4s drawStroke ease-in both;
        }
      }
    }
  }

  &--outer {
    @keyframes rotateRound {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: scale(0.95);
      }
      100% {
        transform: rotate(60deg) scale(0.95);
      }
    }
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../../../images/round.png');
    background-size: 100%;
    animation: 1s rotateRound infinite ease-out alternate;
  }
}
