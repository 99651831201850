@import 'utils/variables.scss';

.slider {
  &__box {
    &--card {
      padding: 2.8rem;

      .title {
        position: relative;
        width: 9.6rem;
        height: 9.6rem;
        margin: 0 auto;
        border-radius: 1rem;
        color: $color-orange-1;
        text-align: center;
        line-height: 9.6rem;
        font-weight: $font-weight-bold;
        font-size: 5rem;
        box-shadow: 0px 0.4rem 3rem rgba(0, 0, 0, 0.1);

        &--count {
          position: absolute;
          bottom: -2rem;
          left: -2rem;
          color: $color-gray-3;
          background: $color-gray-0;
          width: 4.2rem;
          height: 4.2rem;
          line-height: 4.2rem;
          border-radius: 50%;
          font-weight: $font-weight-bold;
          font-size: $font-size-4;
          text-align: center;

          span {
            color: $color-gray-6;
          }
        }
      }
    }
  }
}
