@import 'utils/variables.scss';
@import 'utils/mixins.scss';

.button {
  @include drag-protect;
  &.default {
    border-radius: 0.5rem;
  }
  &.round {
    border-radius: 0.8rem;
  }
  &.orange {
    background-color: $color-orange-1;
    color: $color-white;
    &:hover,
    &.active {
      background-color: $color-orange-2;
    }
  }
  &.white {
    border: 1px solid $default-border;
    &:hover,
    &.active {
      border-color: $color-orange-1;
      color: $color-orange-1;
    }
  }
  &.black {
    background-color: $color-gray-0;
    color: $color-gray-5;
    &:hover,
    &.active {
      background-color: $color-gray-1;
    }
  }
  &.isbold {
    font-weight: $font-weight-bold;
  }
  &--container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -0.4rem;
    button {
      margin: 0 0.4rem;
    }
    &__shrink {
      .button:first-child {
        flex-basis: 33%;
      }
    }
  }
  &--bottom {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0 1.2rem 1rem;
  }

  @include respond-to(max-xs) {
    &--bottom {
      width: auto;
      position: static;
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      button {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
