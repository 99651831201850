@import 'utils/variables.scss';

.bartimer {
  width: 100%;
  height: 0.8rem;
  background-color: $color-timer-background;
  .progresstime {
    width: 0;
    height: 0.8rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: $color-orange-1;
    transition-timing-function: linear;
  }
}
