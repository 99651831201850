@import 'utils/variables.scss';

.header {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 6.2rem;
    padding: 0 2.2rem;
    background-color: $color-white;
    box-shadow: 0 -0.5rem 4.5rem rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 13;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 12;
    a {
      display: inline-block;
      margin-right: 1rem;
    }
  }
  &__menu {
    position: relative;
    z-index: 12;
  }
}
