@import 'utils/variables.scss';

.textarea {
  display: block;
  width: 80%;
  margin: 0 auto;
  border-radius: 0.5rem;
  &:hover {
    border-color: $color-orange-1;
  }
  &:focus {
    border-color: $color-orange-1;
  }
}
