@import 'utils/variables.scss';

.textwitharrow {
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.5rem;
  img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.9rem;
  }
  p {
    font-weight: $font-weight-bold;
    font-size: $font-size-4;
  }
}
