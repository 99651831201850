// ****************
// Color
// ****************

// theme
$color-black: #000000;
$color-white: #ffffff;

$color-gray-0: #444444;
$color-gray-1: #666666;
$color-gray-2: #676767;
$color-gray-3: #9c9c9c;
$color-gray-4: #c8c8c8;
$color-gray-5: #cacaca;
$color-gray-6: #dddddd;

$color-orange-1: #f58220;
$color-orange-2: #f4933f;
$color-orange-3: #ffcb9e;
$color-orange-4: #ffdfc5;

// from default
$default-border: $color-gray-6;
$default-text: $color-gray-2;

//background
$color-wrapper-background: #fafafa;

// nav, header...
$color-timer-background: #efefef;

// result
$color-fail: #ff5050;
$color-success: #5096ff;

$color-wordbox-background: #f2f2f2;

// etc.
$color-hr: #dbdbdb;

// ****************
// Font Size
// ****************

$font-size-base: 1.6rem; // 16px
$font-size-1: $font-size-base * 1.25; // 20px
$font-size-2: $font-size-base * 1.125; // 18px
$font-size-3: $font-size-base * 1; // 16px
$font-size-4: $font-size-base * 0.875; // 14px
$font-size-5: $font-size-base * 0.75; // 12px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.625; // 26px
$h5-font-size: $font-size-base * 1.125; // 18px

// ****************
// Font Weight
// ****************

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

// ****************
// Font Stack
// ****************
$font-stack-ko: 'Noto Sans CJK KR', -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
$font-stack-en: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;

// ****************
// Display width
// ****************

$mobile-portrait: 320px;
$mobile-landscape: 568px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;
$desktop: 1440px;
